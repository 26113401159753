/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ProductCard-AddToCart,
.ProductActions-AddToCart {
    display: flex;
    width: 100%;
    height: 44px;
    column-gap: 9px;
    color: var(--primary-blue-color);

    @include desktop {
        height: 48px;
        column-gap: 10px;
    }

    .CartIcon {
        height: 18px;
        width: 15px;

        #Cart {
            fill: var(--primary-blue-color);
            stroke: var(--primary-blue-color);
        }
    }
}
