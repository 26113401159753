/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CartPage {
    &-TableHead {
        grid-template-columns: 1fr 2fr 1fr;
        background-color: inherit;
        border-top: 1px solid var(--primary-grey-color);
        border-bottom: 1px solid var(--primary-grey-color);
        color: #6c6f7a;
        font-size: 16px;
        font-weight: normal;
        padding: 14px 24px;

        span {
            text-align: end;
        }
    }

    &-Empty {
        margin-block-start: 20px;
    }

    &-Wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 25px;
    }

    &-Static {
        flex-grow: 7;
        flex-basis: 700px;

        hr {
            @include mobile {
                display: none;
            }
        }
    }

    &-Floating {
        flex-basis: 420px;
        flex-grow: 1;
        margin-block-start: 0;

        hr {
            border-top: 1px solid var(--primary-grey-color);
        }
    }

    &-Items {
        display: grid;
        gap: 20px;
        margin-block-start: 40px;
    }

    @include mobile {
        margin-block-start: 0;
    }
}

.SubscriptionModule {
    background: rgba(22, 126, 191, 0.02);
    border: 1px solid rgba(22, 126, 191, 0.1);
    border-radius: 10px;
    align-items: start;
    padding: 30px 30px 30px 10px;
}

.CartPage-PromoText {
    font-size: 16px;
    color: red;
    font-weight: bold;
}

